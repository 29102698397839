/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:00fa73ec-3a19-4003-94bd-51a15e1f3a64",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_N7ExUOBXc",
    "aws_user_pools_web_client_id": "7gade5t3q4ge17jqfoeg1kud85",
    "oauth": {
        "domain": "rocketchallengegame-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://rocket-challenge.com/",
        "redirectSignOut": "https://rocket-challenge.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://2bxhbo2hdrhdhiu3vjknntmbhm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-37iu2twjqvaa3o2deyyla3cuhq",
    "aws_cloud_logic_custom": [
        {
            "name": "NotifyGameStartOpen",
            "endpoint": "https://zlwbv5hl2a.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "FunnEndSessionOpen",
            "endpoint": "https://np82xwnjk3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "rocketchallenge6-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dz5hwdnoak6ic.cloudfront.net"
};


export default awsmobile;
