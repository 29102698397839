import React, { useEffect, useState, useRef } from 'react'
import './App.css';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { API } from 'aws-amplify'
import { listAppSessions, listGamePlays, listPlayers } from '../graphql/queries';
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import PlayerSessionsTable from './components/PlayerSessionsTable/PlayerSessionsTable';
import PlayerGamePlaysTable from './components/PlayerGamePlaysTable/PlayerGamePlaysTable';

const Admin = () => {

  //The user data of the logged in Cognito user
  const [userGroups, setUserGroups] = useState([])
  const [user, setUser] = useState()
  //This is used to prevent the double-execution of the 'prepareSession' function in the below useEffect
  const activeEmail = useRef(null)

  const [players, setPlayers] = useState([])
  const [sessions, setSessions] = useState([])
  const [plays, setPlays] = useState([])

  const [reportRows, setReportRows] = useState([])

  useEffect(() => {

    //Listen for Authentication state changes
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        setUser(authData)
      } else {
        setUser(null)
        setUserGroups([])
      }
    })
  }, [])

  useEffect(() => {
    if (user) {

      const email = user?.attributes?.email;

      if (email === activeEmail.current) return;
      activeEmail.current = email;

      const idTokenStorageKey = Object.keys(localStorage).filter(k => k.indexOf('idToken') > -1)[0]
      const idToken = localStorage.getItem(idTokenStorageKey)

      const jwtInfo = jwt_decode(idToken)

      if (jwtInfo.hasOwnProperty('cognito:groups')) {
        const groups = jwtInfo['cognito:groups']

        setUserGroups(groups)
      }
    }
  }, [user])

  useEffect(() => {
    if (userGroups.indexOf('Admin') > -1) {
      retrievePlayers()
      retrieveGamePlays()
      retrieveSessions()
    }
  }, [userGroups])

  useEffect(() => {
    if (players.length) {
      const rows = players.map(player => ({
        email: player.email,
        joinDate: new Date(player.createdAt),
        sessions: sessions
          .filter(session => session.playerID === player.id)
          .map(session => {

            const beginTime = new Date(session.beginTime)
            const endTime = session.endTime ? new Date(session.endTime) : null

            const dwellTime = ((endTime ? endTime : new Date()).getTime() - beginTime.getTime()) / 1000 //seconds

            return {
              beginTime: beginTime,
              endTime: endTime,
              dwellTime: dwellTime
            }
          })
          .sort((a, b) => b.beginTime - a.beginTime),
        gamePlays: plays
          .filter(play => play.playerID === player.id)
          .map(play => {
            return {
              gameBeginTime: play.gameBeginTime
            }
          })
          .sort((a, b) => b.gameBeginTime - a.gameBeginTime)
      }))
        .sort((a, b) => b.joinDate - a.joinDate)

      setReportRows(rows)
    }
  }, [players, sessions, plays])

  const retrieveSessions = async () => {
    const sessionsCall = await API.graphql({
      query: listAppSessions
    })

    setSessions(sessionsCall.data.listAppSessions.items)
  }

  const retrieveGamePlays = async () => {
    const gamePlaysCall = await API.graphql({
      query: listGamePlays
    })

    setPlays(gamePlaysCall.data.listGamePlays.items)
  }

  const retrievePlayers = async () => {
    //Retrieve the list of players by the logged-in user's email
    const playerListCall = await API.graphql({
      query: listPlayers
    })

    setPlayers(playerListCall.data.listPlayers.items)
  }

  const downloadPlayerList = () => {
    const csvContent = 'data:text/csv;charset=utf-8,'
      + 'Email,Join Date,Visit Count,Total Dwell Time (seconds),Play Count\n'
      + reportRows.map(p => {

        const validSessions = p.sessions.filter(s => s.endTime || s.dwellTime < 3600)

        return [
          p.email,
          p.joinDate,
          p.sessions.length, //Include hanging sessions in count
          validSessions.reduce((totalDwellTime, session) => totalDwellTime + session.dwellTime, 0),
          p.gamePlays.length
        ].join(',')
      }).join('\n')

    console.log(csvContent)

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'RocketChallengePlayers.csv')
    document.body.appendChild(link) //Required for Firefox

    link.click(); //Trigger the download
  }

  //Display the login page or the game, depending on logged-in status
  if (!user) {
    return <>
      <h1>Rocket Challeng Administrative Portal</h1>
      <h3>Ensure your account is connected to the Admin group in the back office</h3>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true
            },
            {
              type: "password",
              required: true
            }
          ]}
        />
      </AmplifyAuthenticator>
    </>
  } else {
    return <>
      <h2>Reports</h2>
      {
        !!reportRows.length && <div>
          <button onClick={downloadPlayerList}>Download Players Report</button>
          <span style={{backgroundColor: 'lightsalmon'}}>Unclosed sessions</span>
          <span>(older than an hour and no end time) are not included in the report's dwell time calculation</span>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th align='left'>Player Email</th>
                <th align='left'>Date Joined</th>
                <th align='center'>Sessions</th>
                <th>Game Plays</th>
              </tr>
            </thead>
            <tbody>
              {reportRows.map((playerRow, index) => <tr key={index} style={{ backgroundColor: (index % 2 === 0 ? 'lightgray' : 'white') }}>
                <td>{playerRow.email}</td>
                <td>{moment(playerRow.joinDate).format('MMMM DD, YYYY hh:mm:ss a')}</td>
                <td>
                  <PlayerSessionsTable sessions={playerRow.sessions} />
                </td>
                <td>
                  <PlayerGamePlaysTable plays={playerRow.gamePlays} />
                </td>
              </tr>)}
            </tbody>
          </table>

        </div>
      }
      <div>

      </div>
    </>
  }

}

export default Admin
