import React, { useEffect, useState, useRef } from 'react'
import { API } from 'aws-amplify'
import { listPlayers } from '../graphql/queries';
import { createAppSession, createPlayer } from '../graphql/mutations';

/**
 * We use react-unity-webgl@7.x because that is the latest version that is compatible with a
 * Unity 2019 project (which Rocket Challenge is). The package react-unity-webgl@8.x
 * does not properly load Unity 2019 projects
 */
import Unity, { UnityContent } from 'react-unity-webgl'

/**
* These are the only two files related to the Unity game that exist in this application's
* 'public' folder.
*
* The other files that end with '.unityweb' exist in a public S3 instance
* because Amplify/AWS restrics the filetypes in the public folder to images, text files,
* javascript, and json files.
*
* All other file types in the 'public' folder are ignored once the project is deployed to AWS.
*/
const unityContent = new UnityContent('Rocket_mobile_fin_webcom_v2.json', 'UnityLoader.js')

const Game = (props) => {

  const {
    user
  } = props

  //This is used to prevent the double-execution of the 'prepareSession' function in the below useEffect
  const activeEmail = useRef(null)

  const visibilityEventListener =

  useEffect(() => {

    const email = user?.attributes?.email;

    //a self-executing asynchronous function is necessary to use async/await inside a useEffect hook.
    (async () => {

      if (email) {

        //Prevent the double execution of the 'prepareSession()' function
        if (email === activeEmail.current) return;
        activeEmail.current = email;

        /**
         * Ensure the player is persisted to the application database.
         * Also initialize a session and start time for the player
         */
        const appSessionId = await prepareSession(email)

        //Save the active email on the DOM for the 'NotifyGameStart()' function
        document.RocketChallengeAppSessionId = appSessionId

        //Prepare the event that will fire upon exiting the application that will notify the session record that it is ended
        document.addEventListener('visibilitychange', async () => {
          let domAppSessionId
          switch (document.visibilityState) {
            case 'hidden':
              domAppSessionId = document.RocketChallengeAppSessionId
              navigator.sendBeacon(`https://np82xwnjk3.execute-api.us-east-1.amazonaws.com/dev/endSession/${domAppSessionId}`)
              break
            case 'visible':
              domAppSessionId = await prepareSession(activeEmail.current)

              //Save the active email on the DOM for the 'NotifyGameStart()' function
              document.RocketChallengeAppSessionId = domAppSessionId
              break
            default:
              break
          }
        })

        /**
         * Safari doesn't fire visibilityChange when turning to hidden.
         * This is the workaround, but it isn't perfect.
         *
         * pageHide does not fire in the following circumstance.
         * 1. A mobile user visits your page.
         * 2. The user then switches to a different app
         * 3. Later, the user closes the browser from the app manager.
         *
         * But this is the best we can do to cover Safari
         */
        window.addEventListener('pagehide', event => {
          const domAppSessionId = document.RocketChallengeAppSessionId
          navigator.sendBeacon(`https://np82xwnjk3.execute-api.us-east-1.amazonaws.com/dev/endSession/${domAppSessionId}`)
        })
      }
    })();

    //Cleanup function to run on unmount
    return () => {
      document.removeEventListener('visibilitychange', visibilityEventListener)
      document.RocketChallengeAppSessionId = null
    }
  }, [user])

  const prepareSession = async (email) => {

    //Retrieve the list of players by the logged-in user's email
    const playerListCall = await API.graphql({
      query: listPlayers,
      variables: {
        filter: {
          "email": {
            eq: email
          }
        }
      }
    })

    const players = playerListCall.data.listPlayers.items;
    let player = null

    //If the player's record doesn't yet exist, create it
    if (!players.length) {
      const playerCreateCall = await API.graphql({
        query: createPlayer,
        variables: {
          input: {
            email: email
          }
        }
      })

      player = playerCreateCall.data.createPlayer
    } else {
      player = players[0]
    }

    //Create the new App Session record for the logged-in player
    const appSessionCall = await API.graphql({
      query: createAppSession,
      variables: {
        input: {
          playerID: player.id,
          beginTime: new Date().toISOString()
        }
      }
    })

    //Return the new App Session ID
    return appSessionCall.data.createAppSession.id
  }

  return <Unity unityContent={unityContent} />

}

export default Game
