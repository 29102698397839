/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppSession = /* GraphQL */ `
  query GetAppSession($id: ID!) {
    getAppSession(id: $id) {
      id
      playerID
      beginTime
      endTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAppSessions = /* GraphQL */ `
  query ListAppSessions(
    $filter: ModelAppSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerID
        beginTime
        endTime
        player {
          id
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGamePlay = /* GraphQL */ `
  query GetGamePlay($id: ID!) {
    getGamePlay(id: $id) {
      id
      playerID
      gameBeginTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGamePlays = /* GraphQL */ `
  query ListGamePlays(
    $filter: ModelGamePlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGamePlays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerID
        gameBeginTime
        player {
          id
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
