import React, { useEffect, useState, useRef } from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { BrowserRouter as Router,Route } from "react-router-dom";
import Game from './Home/Game';
import Admin from './admin/app';
import Login from './login';
import './App.css';
export const UserContext = React.createContext();
const App = () => {

  //The user data of the logged in Cognito user
  const [user, setUser] = useState()

  //This is used to prevent the double-execution of the 'prepareSession' function in the below useEffect
  const activeEmail = useRef(null)

  useEffect(() => {

    //Listen for Authentication state changes
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        setUser(authData)
      } else {
        setUser(null)
      }
    })
  }, [])

  useEffect(() => {
    if (user) {

      const email = user?.attributes?.email;

      if (email === activeEmail.current) return;
      activeEmail.current = email;
    }
  }, [user])
  //Display the login page or the game, depending on logged-in status
    return (
        <>
      {
        !user ?
            <Login />
            :
            <UserContext.Provider value={{user}}>
              <div className="app-container">
                <Router>
                  <div>
                  <Route exact path="/admin" component={Admin} />
                   <Route exact path="/">
                     <Game user={user} />
                   </Route>
                  </div>
                </Router>
              </div>
            </UserContext.Provider>

  }
  </>
)
}

export default App
