/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createAppSession = /* GraphQL */ `
  mutation CreateAppSession(
    $input: CreateAppSessionInput!
    $condition: ModelAppSessionConditionInput
  ) {
    createAppSession(input: $input, condition: $condition) {
      id
      playerID
      beginTime
      endTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppSession = /* GraphQL */ `
  mutation UpdateAppSession(
    $input: UpdateAppSessionInput!
    $condition: ModelAppSessionConditionInput
  ) {
    updateAppSession(input: $input, condition: $condition) {
      id
      playerID
      beginTime
      endTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppSession = /* GraphQL */ `
  mutation DeleteAppSession(
    $input: DeleteAppSessionInput!
    $condition: ModelAppSessionConditionInput
  ) {
    deleteAppSession(input: $input, condition: $condition) {
      id
      playerID
      beginTime
      endTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGamePlay = /* GraphQL */ `
  mutation CreateGamePlay(
    $input: CreateGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    createGamePlay(input: $input, condition: $condition) {
      id
      playerID
      gameBeginTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGamePlay = /* GraphQL */ `
  mutation UpdateGamePlay(
    $input: UpdateGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    updateGamePlay(input: $input, condition: $condition) {
      id
      playerID
      gameBeginTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGamePlay = /* GraphQL */ `
  mutation DeleteGamePlay(
    $input: DeleteGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    deleteGamePlay(input: $input, condition: $condition) {
      id
      playerID
      gameBeginTime
      player {
        id
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
