import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'

const PlayerGamePlaysTable = (props) => {
    const {
        plays
    } = props
    
    const [expanded, setExpanded] = useState(false)

    return !expanded
        ? <div style={{textAlign: 'center'}}>
            {
                !!plays.length ? <>
                    <button onClick={() => { setExpanded(true) }}>Expand</button>
                    <span style={{display: 'inline-block', width: '100px'}}>Count: {plays.length}</span>
                </> : <span>None</span>
            }
        </div>
        : <>
            <div style={{textAlign: 'center'}}><button onClick={() => { setExpanded(false) }}>Collapse</button></div>
            <table width='100%'>
                <thead>
                    <tr>
                        <th>Game Begin Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        plays?.map((play, index) => <tr key={index}>
                            <td align='center'>{moment(play.gameBeginTime).format('MMMM DD, YYYY hh:mm:ss a')}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
}

export default PlayerGamePlaysTable