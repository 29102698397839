import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'

const PlayerSessionsTable = (props) => {
    const {
        sessions
    } = props

    const [expanded, setExpanded] = useState(false)

    return !expanded
        ? <div style={{textAlign: 'center'}}>
            {
                !!sessions.length ? <>
                    <button onClick={() => { setExpanded(true) }}>Expand</button>
                    <span style={{display: 'inline-block', width: '100px'}}>Count: {sessions.length}</span>
                </> : <span>None</span>
            }
        </div>
        : <>
            <div style={{textAlign: 'center'}}><button onClick={() => { setExpanded(false) }}>Collapse</button></div>
            <table width='100%'>
                <thead>
                    <tr>
                        <th>Begin Time</th>
                        <th>End Time</th>
                        <th>Dwell Time (seconds)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sessions?.map((session, index) => <tr key={index} style={{backgroundColor: (!session.endTime && session.dwellTime > 3600 && 'lightsalmon')}}>
                            <td align='center'>{moment(session.beginTime).format('MMMM DD, YYYY hh:mm:ss a')}</td>
                            <td align='center' style={{paddingLeft: '8px'}}>{session.endTime ? moment(session.endTime).format('MMMM DD, YYYY hh:mm:ss a') : 'ongoing'}</td>
                            <td align='right'>{session.dwellTime.toString()}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
}

export default PlayerSessionsTable