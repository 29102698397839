import React, {useEffect,useState} from 'react'
import {Auth} from "aws-amplify";
import {isMobile} from 'react-device-detect';
import { AmplifyAuthenticator, AmplifySignUp,AmplifySignIn } from '@aws-amplify/ui-react'
import {Button,Modal} from 'react-bootstrap';
import Facebookicon from "./facebook.png";
import Googleicon from "./google.png";
import './App.css';

const federated = {
    google_client_id: '620946284567-1m7468uakba2f6lo2clo4ajj43dv2ao8.apps.googleusercontent.com',
    facebook_client_id: '520311459008296'
};
const Login = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        if (isMobile) {
            setShow(true)
        }
    }, [])
        return(
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Game experience best when starting from Rocket Challenge icon on Home Screen. Follow directions below to install.
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            For Android:
                        </p>
                        <ol>
                            <li>Sign into the app</li>
                            <li>Tap the three vertical dots button and select "Add to Home screen."</li>
                            <li>Follow the onscreen instructions</li>
                        </ol>

                        <p>
                            For iPhone:
                        </p>
                        <ol>
                            <li>Sign into the app</li>
                            <li>Tap the share button in Safari</li>
                            <li>Tap the icon labeled "Add to Home Screen."</li>
                        </ol>
                        <p>HOLD MOBILE DEVICES HORIZONTALLY.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
    <AmplifyAuthenticator federated={federated} usernameAlias="email">
            <AmplifySignIn
                headerText="Sign In to Rocket Challenge"
                slot="sign-in"
                federated={federated}
            >
                <div slot="federated-buttons">
                    <Button onClick={()=> Auth.federatedSignIn({provider: 'Google'})} variant="light"  block className="sbutton">
                        <img src={Googleicon} /> Sign In with Google
                    </Button>
                    <Button onClick={()=> Auth.federatedSignIn({provider: 'Facebook'})} variant="light"  block className="utton">
                        <img src={Facebookicon} /> Sign In with Facebook
                    </Button>
                </div>
            </AmplifySignIn>
            <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                    {
                        type: "email",
                        required: true
                    },
                    {
                        type: "password",
                        required: true
                    }
                ]}
            />
        </AmplifyAuthenticator>
                </>
        )

}

export default Login
